import React from 'react'
import { graphql, Link } from 'gatsby'
import { DateTime } from 'luxon'
import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'
import FlexibleLink from '@c/common/FlexibleLink'
import DictionaryItem from '@m/DictionaryItem'
import DatoCmsLink from '@m/datocms-components/DatoCmsLink'
import makeSlug from '@u/make-slug'
import useForceBlank from '../../hooks/use-force-blank'
import ProjectImage from '@/components/project-image'
const Page = ({ data, pageContext }) => {
  const {
    term,
    dictionaryPage,
    pageSettings,
    prevTerm,
    nextTerm,
    allDatoCmsAiDictionaryPage
  } = data
  const {
    resourcesSlug,
    // resourcesTitle,
    resourcesArticleSlug,
    categorySlug
  } = pageSettings
  const model = new DictionaryItem(term)
  const parentPages = [
    {
      title: dictionaryPage.title,
      url: makeSlug.generic(term.locale, dictionaryPage.slug)
    }
  ]

  if (term.category) {
    parentPages.push({
      title: term.category.title,
      url: makeSlug.generic(
        term.locale,
        dictionaryPage.slug,
        term.category.slug
      )
    })
  }
  const content = useForceBlank(term.description)

  return (
    <Layout
      locale={term.locale}
      seo={{ ...model.getSeoComponentProps(allDatoCmsAiDictionaryPage) }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <Breadcrumb
              className="resource-detail-breadcrumb"
              parentPages={parentPages}
              currentPage={term.title}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <article className="blog-post">
          {/* header */}
          <header className="blog-post-header">
            {/* meta */}
            <div className="row">
              <div className="col-12 offset-lg-2 col-lg-8">
                {/* title */}
                <PageHeader title={term.title} />
              </div>
            </div>
          </header>

          {/* body */}
          <div className="blog-post-body">
            <div className="row">
              <div className="col-12 offset-lg-2 col-lg-8">
                <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />

                {/* AUTHORITIES' ARTICLES */}
                <div className="mt-32">
                  <h3>{dictionaryPage.authorityArticlesSectionTitle}</h3>

                  {term.authorityLinks.map((link, i) => {
                    const dlink = new DatoCmsLink(link)

                    return (
                      <FlexibleLink
                        key={i}
                        className="d-block mt-8"
                        title={dlink.getTitle()}
                        url={dlink.getUrl()}
                        target="_blank"
                      />
                    )
                  })}
                </div>
                {/* / AUTHORITIES' ARTICLES */}
              </div>
            </div>
          </div>
        </article>
      </div>

      {/* PREVIOUS/NEXT TERMS */}
      <div className="container">
        <div className="col-12 offset-lg-2 col-lg-8 d-flex flex-wrap align-items-center justify-content-between mt-60 pl-4">
          {prevTerm && !(prevTerm.id === term.id) && (
            <Link
              className="d-flex align-items-center ml-n16"
              to={makeSlug.generic(
                pageContext.locale,
                dictionaryPage.slug,
                prevTerm.slug
              )}
            >
              <img
                className="award-title-img"
                width="60"
                src="/assets/common/icon-double-right.svg"
              />
              <div>
                <p>{dictionaryPage.previousTermButtonTitle}</p>
                <p>{prevTerm.title}</p>
              </div>
            </Link>
          )}

          {nextTerm && !(nextTerm.id === term.id) && (
            <Link
              className="d-flex align-items-center mt-32 mt-lg-0 ml-auto mr-n16"
              to={makeSlug.generic(
                pageContext.locale,
                dictionaryPage.slug,
                nextTerm.slug
              )}
            >
              <div>
                <p>{dictionaryPage.nextTermButtonTitle}</p>
                <p>{nextTerm.title}</p>
              </div>
              <img width="60" src="/assets/common/icon-double-right.svg" />
            </Link>
          )}
        </div>
      </div>
      {/* / PREVIOUS/NEXT TERMS */}

      {/* RELATED TERMS */}
      <div className="container">
        <div className="col-12 offset-lg-2 col-lg-7 mt-80 pl-0">
          <h3>{dictionaryPage.relatedTermsTitle}</h3>
          <div className="d-flex mt-32 flex-column flex-lg-row">
            {data.relatedTerms.nodes.map(term => {
              return (
                <Link
                  key={term.id}
                  to={makeSlug.generic(
                    pageContext.locale,
                    dictionaryPage.slug,
                    term.slug
                  )}
                  className="col-12 col-lg-3 mt-32 mt-lg-0 mr-32 px-32 py-16 border-all ta-center"
                >
                  {term.title}
                </Link>
              )
            })}
          </div>
        </div>
      </div>
      {/* / RELATED TERMS */}

      {/* RELATED ARTICLES */}
      {!!data?.relatedArticles?.nodes?.length && (
        <div className="container mt-80">
          <div className="col-12 offset-lg-2 col-lg-8 pl-0">
            <h3>{dictionaryPage.relatedArticlesTitle}</h3>
            {data.relatedArticles.nodes.map(article => {
              return (
                <div
                  key={article.id}
                  className="d-flex flex-wrap justify-content-between border-all mt-32"
                >
                  <div className="col-12 col-lg-8 d-flex flex-column align-items-start justify-content-around py-32">
                    {article.category && (
                      <Link
                        to={makeSlug.generic(
                          pageContext.locale,
                          resourcesSlug,
                          resourcesArticleSlug,
                          categorySlug,
                          article.category.slug
                        )}
                      >
                        {article.category.title}
                      </Link>
                    )}
                    <h5 className="my-8">
                      <Link
                        to={makeSlug.generic(
                          pageContext.locale,
                          resourcesSlug,
                          resourcesArticleSlug,
                          article.slug
                        )}
                      >
                        {article.title}
                      </Link>
                    </h5>
                    <p className="c-soft-red">
                      {DateTime.fromJSDate(
                        new Date(article.publishDate)
                      ).toRelative({ locale: pageContext.locale })}
                    </p>
                  </div>
                  {article?.image && (
                    <div className="col-12 col-lg-4 py-16 px-16">
                      <ProjectImage
                        image={article?.image}
                        objectFit={'contain'}
                        style={{ width: '100%' }}
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )}
      {/* / RELATED ARTICLES */}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query AiDictionaryDetailQuery(
    $locale: String
    $termId: String
    $prevTermId: String
    $nextTermId: String
    $relatedArticleIds: [String]
    $relatedTermIds: [String]
  ) {
    term: datoCmsAiDictionaryTerm(id: { eq: $termId }) {
      _allSlugLocales {
        locale
        value
      }
      id
      locale
      title
      description
      authorityLinks {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }
      category {
        slug
        title
      }
      metaTags {
        ...MetaTags
      }
    }

    allDatoCmsAiDictionaryPage {
      nodes {
        slug
        locale
      }
    }
    dictionaryPage: datoCmsAiDictionaryPage(locale: { eq: $locale }) {
      slug
      title
      authorityArticlesSectionTitle
      previousTermButtonTitle
      nextTermButtonTitle
      relatedTermsTitle
      relatedArticlesTitle
    }

    prevTerm: datoCmsAiDictionaryTerm(id: { eq: $prevTermId }) {
      id
      slug
      title
    }

    nextTerm: datoCmsAiDictionaryTerm(id: { eq: $nextTermId }) {
      id
      slug
      title
    }

    relatedArticles: allDatoCmsArticle(
      filter: { id: { in: $relatedArticleIds }, slug: { regex: "/.+/" } }
    ) {
      nodes {
        id
        title
        slug
        category {
          slug
          title
        }
        publishDate

        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 180, height: 110)
              }
            }
          }
        }
      }
    }

    relatedTerms: allDatoCmsAiDictionaryTerm(
      filter: { id: { in: $relatedTermIds }, slug: { regex: "/.+/" } }
    ) {
      nodes {
        id
        title
        slug
      }
    }

    # page settings
    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allResourcesSlugLocales {
        locale
        value
      }
      _allResourcesArticleSlugLocales {
        locale
        value
      }
      resourcesTitle
      resourcesSlug
      resourcesArticleTitle
      resourcesArticleSlug
      categorySlug
    }
  }
`
